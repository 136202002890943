import React from "react"

import PlainTextPageLayout from "../components/layout/plain-text-page-layout/plain-text-page-layout"
import PageHeader from "../components/page-header/page-header"
import SEO from "../components/seo"
import LocalizedLink from "../i18n/localized-link"
import { useTranslation } from "../i18n/translate"
import "./404.sass"

const NotFoundPage = ({ location }) => {
  const { t } = useTranslation()
  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("404-META_TITLE")}
        descriptionTranslatedString={""}
      />
      <PageHeader
        container={"secondary"}
        levelTwoTitle={t("404-META_TITLE")}
        levelThreeTitle={t("404-META_TITLE")}
      />

      <div className={"_fp-global-container-secondary _fp-404"}>
        <p className={"_fp-heading-2"}>{t("404-CONTENT_HEADING")}</p>
        <p className={"_fp-text"}>{t("404-CONTENT_TEXT")}</p>
        <LocalizedLink className={"_fp-cta-link _fp-cta-link--large"} to={"/"}>
          {t("404-GO_TO_HOME_PAGE_CTA")}
        </LocalizedLink>
      </div>
    </>
  )
}

export default NotFoundPage
